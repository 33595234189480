.main {
	width: 100%;
	height: auto;
}

#login {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
}

/* .main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
} */

.fields {
	flex-basis: 100%;
}

@media (min-width: 350px) {
	.main {
		flex-wrap: nowrap;
	}

	.fields {
		flex-basis: 33%;
	}
}
